import React from 'react';
import {ReactSVG} from 'react-svg';
import {withPrefix} from 'gatsby';
import PropTypes from 'prop-types';
import SliderSectionPrevArrow from '../SliderSectionPrevArrow/SliderSectionPrevArrow';

const SliderSectionNextArrow = ({wrapClass, className, onClick}) => {
	return (
		<>
			<button className={className} onClick={onClick}>
				<ReactSVG className={wrapClass} src={withPrefix('images/svg/arrow-next.svg')}/>
			</button>
		</>
	)
};

SliderSectionPrevArrow.propTypes = {
	onClick: PropTypes.func,
	wrapClass: PropTypes.string,
	className: PropTypes.string
};

export default SliderSectionNextArrow;
