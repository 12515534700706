import React, {useEffect, useRef} from 'react';
import {useAnimation, motion} from 'framer-motion';
import classes from './StorySelector.module.scss';
import {ebcStories} from '../../constants/main-menu';
import {Link} from 'gatsby';
import useSticky from '../../hooks/useSticky';
import {useInView} from 'react-intersection-observer';

const StorySelector = () => {
	const [ref, inView] = useInView({
		threshold: .1,
		triggerOnce: true,
		rootMargin: '320px'
	});
	const storiesAnim = useAnimation();

	const navRef = useRef(null);
	const [selectorPosState] = useSticky(navRef);

	useEffect(() => {
		if (inView) {
			storiesAnim.start('visible');
		}
	}, [inView]);

	return (
		<>
			<div ref={ref}>
				<motion.div
					animate={storiesAnim}
					initial="hidden"
					variants={{
						visible: {opacity: 1, x: 0},
						hidden: {opacity: 0, x: '-500px'}
					}}
					transition={{type: 'spring', stiffness: 40, delay: .5}}
					className={classes.selector}
				>

					<nav className={classes.navigation} ref={navRef} css={{
						position: selectorPosState.top < 0 ? 'fixed' : 'relative',
						top: selectorPosState.top
					}}>
						<h3>Select a story</h3>
						{
							ebcStories.map(({name, href}, idx) => (
								<Link to={href}
								      key={`story-link-${idx}`}
								      activeClassName={classes.navLinkActive}
								>{name}</Link>
							))
						}
					</nav>

				</motion.div>
			</div>
		</>
	)
};

export default StorySelector;
