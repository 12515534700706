export default {
	pageTitle: 'Smart Cities | Department for International Trade',
	pageDescription: `From nuclear fusion and smart buildings to electric vehicles, UK companies are spearheading the clean-tech revolution.`,
	heroSectionOne: {
		main: {
			title: 'The net-zero cities',
			author: 'WRITTEN BY',
			brandPath: 'images/EBCONPRGB.svg',
			content: `
			<p>
				Advancing climate action through clean-tech innovation 
			</p>
		`
		},
		banner: {
			firstImg: 'images/Hero_shutterstock_608459744.jpg',
			secondImg: 'images/svg/square-gradient.svg'
		},
	},
	sliderSectionOne: {
		headerImgPath: 'images/innovation-is-great.png',
		headerImgAlt: 'Innovation is great img alt',
		transparentBg: true,
		settings: {
			dots: false,
			arrows: false,
			infinite: false,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 2.2,
						slidesToScroll: 1,
						draggable: true
					}
				}, {
					breakpoint: 767,
					settings: {
						slidesToShow: 1.2,
						slidesToScroll: 1,
						draggable: true,
						arrows: true
					}
				}
			]
		},
		items: [
			{
				img: 'images/homepage_fintech.jpg',
				title: 'Fintech',
				text: 'The advent of open banking and emerging fintech community has shifted the UK towards a pro-innovation mindset.',
				more: {
					label: 'Read more',
					link: '/story/fintech/'
				}
			}, {
				img: 'images/home_edtech.jpg',
				title: 'Edtech',
				text: 'Education was once lagging in digital innovation, but this is all changing. UK-based edtech startup, Twig Education, is blending learning and technology to bring subjects to life.',
				more: {
					label: 'Read more',
					link: '/story/edtech/'
				}
			}, {
				img: 'images/home_cybersecurity.jpg',
				title: 'Cybersecurity',
				text: 'The rise in cyberattacks and identity fraud has made managing our digital identities increasingly challenging. But a wave of UK startups and SMEs is helping to raise awareness in the corporate community.',
				more: {
					label: 'Read more',
					link: '/story/cybersecurity/'
				}
			},
		]
	},
	cardSectionOne: {
		position: 'left',
		bgImg: 'images/svg/square-gradient.svg',
		main: {
			title: '',
			content: `
			<p style="margin-bottom: 35px">
			With the US recommitting to the Paris Agreement on climate, there is growing optimism that the international 
			community can make progress in what is a make-or-break decade for limiting global warming. As holder of the G7 
			presidency and host of the COP26 summit at the end of the year, the UK, birthplace of the Industrial Revolution, 
			can also play a catalytic role in bringing forth the 21st-century clean energy transformation. 
			</p>
			<p>
			Already, successive UK leaders have pushed the climate agenda, from Theresa May’s commitment to achieve net-zero 
			carbon by 2050 to the recent <a target="_blank" href="https://www.gov.uk/government/news/uk-sets-ambitious-new-climate-target-ahead-of-un-summit">
			10-point climate plan</a> that would see the country reduce emissions at a faster rate than any other major economy. 
			But nothing can be delivered without technological breakthroughs. From nuclear fusion and smart buildings to electric 
			vehicles (EV), UK companies are spearheading the clean-tech revolution.
			</p>
`
		},
		additional: {
			img: 'images/In_article_02.jpg',
			comment: {
				text: 'It’s not a question of whether net zero will happen—just of how quickly it ' +
					'will ramp up.',
				subtext: 'Toddington Harper, founder and CEO of Gridserve'
			}
		},
	},
	cardSectionTwo: {
		position: 'left',
		bgImg: 'images/svg/square-gradient.svg',
		main: {
			title: 'Trade and investment challenges and opportunities in the post-pandemic world',
			content: `<p>The pandemic has transformed the global trade and investment landscape, but offers unique 
			opportunities to agile and resilient businesses in sectors of the future.</p>`
		},
		additional: {
			img: 'images/homepage_chart.jpg',
		},
		more: {
			label: 'Read More',
			link: '/briefing-paper/'
		}
	},
	contentOne: `
		<p>
			<span>Cleaner and greener urban commutes</span>
		</p>
		<p>
			Cities consume over <a target="_blank" href="https://www.c40.org/why_cities">two-thirds</a> of the world’s energy and produce more
			 than 70% of CO<sub>2</sub> emissions—and it is already 
			harming human respiratory health. In December, a UK coroner 
			<a target="_blank" href="https://www.theguardian.com/environment/2020/dec/16/ella-kissi-debrah-mother-fight-justice-air-pollution-death">
			made history</a> by attributing air pollution as a cause of 
			death for the first time. And a recent <a target="_blank" href="https://www.thelancet.com/journals/lanplh/article/PIIS2542-5196(20)30272-2/fulltext">
			study</a> of European cities found compliance with World Health Organisation (WHO) 
			air pollution guidelines could prevent more than 50,000 deaths per year.
		</p> 
		<p>
			“The emissions problem is compounded in cities because there are so many cars and [so much] particulate matter in a 
			condensed space,” says Toddington Harper, founder and CEO of Gridserve, a UK-based business that builds EV charging 
			infrastructure. “By the same token, the opportunities are exponentially greater.” 
		</p>
		<p>
			A plan to ban the sale of new petrol and diesel cars by 2030 is quickening the shift to electrification, but this 
			hinges on the availability of reliable charging facilities, of which Gridserve is among the leading providers. Its 
			recent launch of a state-of-the-art EV forecourt in 
			<a target="_blank" href="https://www.autocar.co.uk/car-news/features/whats-it-uks-first-bespoke-electric-car-forecourt">Braintree, Essex</a>, 
			with plans for another 100 or more in the 
			next five years, is helping to drive EV adoption and venture investment.
		</p>
		<p>
			Gridserve is just one of many clean-tech firms to come out of the UK, according to Tim Kay, deputy director for 
			technology at the Department for International Trade (DIT), who also highlights nuclear fusion startup Tokamak 
			Energy, AI-powered smart energy company <a target="_blank" href="https://gridedge.ai/">Grid Edge</a> and recyclable battery 
			innovator <a target="_blank" href="https://www.aceleronenergy.com/">Aceleron Energy</a>. Green urban 
			transport is a major category. <a target="_blank" href="https://arrival.com/">Arrival</a>, founded in 2015 and garnering 
			investment from Hyundai and Kia Motors, is 
			delivering electric light commercial vehicles around the world. Demand for electric vans alone is projected to 
			grow by 37% by 2024, creating an addressable market of <a target="_blank" href="https://arrival.com/?topic=investors&id=20">$280bn</a>. 
			The UK is also producing a crop of green utility 
			companies, such as <a target="_blank" href="https://technation.io/news/2019-a-record-year-for-uk-tech/">OVO Energy and Bulb</a>, 
			that are helping drive the consumer transition.  
		</p>
		<p>
			Mr Harper describes a “transformation” of clean-tech in recent years, thanks to falls in costs of solar energy 
			and batteries and the clarity provided by the UK government. He says commitments to net zero have set a clearer 
			direction than subsidies, which are overly political and subject to reversal. "It’s not a question of whether net 
			zero will happen—just of how quickly it will ramp up."
		</p>
	`,
	contentTwo: `
		<p>
			<span>A look at London and beyond</span>
		</p>
		<p>
			Cities are crucial arenas for decarbonisation, making municipal leaders key actors in promoting innovation and 
			shifting behaviour. 
			<a target="_blank" href="https://c40-production-images.s3.amazonaws.com/other_uploads/images/2351_DECLARATION_PROGRESS_G_H_180919.original.pdf?1568791998">London</a>, 
			responsible for <a target="_blank" href="https://www.london.gov.uk/what-we-do/planning/london-plan/current-london-plan/london-plan-chapter-five-londons-response/policy-51-climate-change-mitigation">
			nearly a tenth</a> of UK emissions, was an early adopter of a congestion 
			charge in 2003. This was upgraded in April 2019 to the world’s first Ultra Low Emission Zone (ULEZ), levying 
			charges on vehicles based on emissions performance. 
		</p>
		<p>
			By the end of 2019, London boasted one of Europe’s largest electric bus fleets, with 240 electric buses, and now 
			has its first fully electric <a target="_blank" href="https://c40-production-images.s3.amazonaws.com/other_uploads/images/2351_DECLARATION_PROGRESS_G_H_180919.original.pdf?1568791998">double-decker bus routes</a>.
			 Under the mayor’s EV Infrastructure Taskforce, by August 
			2019 London had delivered more than 200 rapid charge points, one of the largest such networks in the world. All 
			newly licensed black taxis must be zero-emission capable, and a fully electric black taxi model is expected this 
			year. 
		</p>
		<p>
			There is progress beyond the capital, too. Oxford, Liverpool, Birmingham and Greater Manchester all joined London 
			in signing up to C40 Cities’ <a target="_blank" href="https://www.c40.org/other/green-and-healthy-streets">
			Green and Healthy Streets Declaration</a>, a pledge by mayors to procure only 
			zero-emission buses from 2025 and ensure significant areas of cities are zero emission by 2030. Oxford has 
			retrofitted 115 local buses to Euro 6 emissions standards, incentivised drivers of black taxis to convert to 
			electric, installed EV infrastructure and encouraged modal shifts to walking and cycling. In 2019, the city 
			announced the UK’s first <a target="_blank" href="https://www.oxford.gov.uk/info/20011/environment/1343/oxford_citizens_assembly_on_climate_change">
			‘citizens assembly’</a> to deal with climate change.
		</p> 
		<p>
			UK tech companies are helping cities achieve their decarbonisation goals. Zenobe Energy, for instance, provides 
			energy storage technology to a fifth of the UK bus market and has attracted investment from M&G and Japan’s TEPCO. 
			The UK is also at the cutting edge of digital energy, which will be critical to smart cities and their low-carbon 
			networks. 
		</p>
		<p>
			5G connectivity is a crucial enabler of decarbonisation, argues Mr Kay, due to the data-heavy requirements of the 
			sensors and the Internet of Things (IoT)-based capabilities needed for demand-supply matching and energy analytics. 
			Mr Kay cites the <a target="_blank" href="https://5pring.org/">West Midlands Test Bed programme<a/> as one venture that is developing 
			the technologies to enable 
			next-generation public infrastructure and assets. 
		</p>
		<p>
			Government-backed innovation funding is also helping. Tech Nation, a national network to promote startups, 
			launched a <a target="_blank" href="https://technation.io/programmes/net-zero/">Net Zero programme</a> in September 2020 
			to support scale-ups in energy, transport and mobility, food and 
			manufacturing. The UK government also launched a <a target="_blank" href="https://www.gov.uk/government/news/government-launches-new-40-million-clean-growth-fund-to-supercharge-green-start-ups">
			£40m</a> clean growth fund in May 2020. 
		</p>
		<p>
			The UK has its share of disadvantages when it comes to decarbonisation. The infrastructure is deeply established, 
			built decades or even centuries ago, whereas more capital-rich emerging markets in the UAE and Saudi Arabia, for 
			example, are able to build green cities from scratch. 
		</p>
		<p>
			But the country’s leadership in science and engineering means homegrown companies are helping nations overseas on 
			their own decarbonisation journey. UK clean-tech companies attracted <a target="_blank" href="https://sifted.eu/articles/the-uk-cleantech-revolution/">
			73% more venture capital</a> investment in 2019 
			than 2018, nearly double China’s 37% rate of investment growth. Asian automotive corporates have been impressed 
			by—and invested in—the UK’s clean-tech sector. DIT recently led a clean-tech ‘virtual mission’ to the Middle East 
			to support governments in the region as they look to move from oil to solar. 
		</p>
		<p>
			Mr Kay believes the UK’s investor community is waking up to the commercial promise of green energy. "Initially, 
			funding was led by purpose-driven investors who had a passion for sustainability. But over the past 18 months or 
			so, we have seen investors across the spectrum understand there is a commercial return to be had in the UK, because 
			our companies are world-leading."
		</p>
	`,
	additionalBottomText:
		`<p>Produced for DIT by (E) BrandConnect, a commercial division of The Economist Group, which operates 
		separately from the editorial staffs of The Economist and The Economist Intelligence Unit. Neither (E) BrandConnect 
		nor its affiliates accept any responsibility or liability for reliance by any party on this content.</p>`
}
