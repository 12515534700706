import React, {useEffect, useRef, useState} from 'react';
import {motion, useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import classes from './SliderSection.module.scss';
import {withPrefix} from "gatsby";
import Slider from "react-slick";
import SliderSectionSlide from './components/SliderSectionSlide/SliderSectionSlide';
import SliderSectionNextArrow from './components/SliderSectionNextArrow/SliderSectionNextArrow';
import SliderSectionPrevArrow from './components/SliderSectionPrevArrow/SliderSectionPrevArrow';
import {useWindowWidth} from '@react-hook/window-size/throttled';
import config from '../../../config/config';
import PropTypes from 'prop-types';
import HeadAdvAlt from '@economist/fabric-components/header/headadv/HeadAdv';

const defaultSettings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1
};

const SliderSection = ({headerImgPath = false, headerImgAlt = false, transparentBg = false, settings, items = [], scale = .5}) => {
	let prevBtnRef = useRef(null);
	const windowWidth = useWindowWidth();
	const [arrowBtnsMargin, setArrowBtnsMargin] = useState(0);
	const [ref, inView] = useInView({
		threshold: .3,
		triggerOnce: true,
	});
	const sectionAnim = useAnimation();
	const bgImgAnim = useAnimation();

	let sliderSettings = {
		...defaultSettings,
		...settings
	};

	const updateArrowBtnsMargin = (coef) => {
		if (prevBtnRef.current && windowWidth <= config.mobileWidth) {
			setArrowBtnsMargin((prevBtnRef.current.parentElement.querySelector('img').getBoundingClientRect().height / coef) / 2);
		}
	};

	useEffect(() => {
		if (inView) {
			sectionAnim.start('visible');
			bgImgAnim.start('visible');
			updateArrowBtnsMargin(scale);
		}
	}, [windowWidth, inView]);
	useEffect(() => updateArrowBtnsMargin(1), [windowWidth]);

	if (!items.length) return null;

	return (
		<>
			<div ref={ref}>
				<motion.div
					animate={sectionAnim}
					initial="hidden"
					variants={{
						visible: {opacity: 1, y: 0},
						hidden: {opacity: 0, y: '500px'}
					}}
					transition={{type: 'spring', stiffness: 40}}
					style={{background: transparentBg ? 'none' : null}}
					className={classes.sliderSection}
				>

					<motion.div className={classes.header}>
						{
							headerImgPath
								? <div><img srcSet={withPrefix(headerImgPath)} alt={headerImgAlt}/></div>
								: null
						}

						<HeadAdvAlt sponsoredText="written by"
												bgColor="white"
												sponsorLogo={withPrefix('images/EBCONPRGB.svg')}
												border={false}/>
					</motion.div>

					<motion.div className={classes.content}>
						<motion.div
							animate={bgImgAnim}
							initial="hidden"
							variants={{
								visible: {opacity: 1, x: 0},
								hidden: {opacity: 0, x: '-600px'}
							}}
							transition={{type: 'spring', stiffness: 30}}
							className={classes.bgImgWrapper}
							style={{
								backgroundImage: `url(${withPrefix('images/svg/square-gradient.svg')})`,
								zIndex: transparentBg ? -1 : 0
							}}
						/>
						<Slider
							{...sliderSettings}
							prevArrow={<SliderSectionPrevArrow ref={prevBtnRef} css={{top: `${arrowBtnsMargin}px`}}
							                                   wrapClass={classes.prevArrow}/>}
							nextArrow={<SliderSectionNextArrow css={{top: `${arrowBtnsMargin}px`}}
							                                   wrapClass={classes.nextArrow}/>}
						>
							{items.map((item, idx) =>
								<SliderSectionSlide
									isVisible={inView}
									scale={scale}
									key={`slider-item-${idx}`} {...item} />
							)}
						</Slider>
					</motion.div>

				</motion.div>
			</div>
		</>
	)
};

SliderSection.propTypes = {
	settings: PropTypes.object.isRequired,
	items: PropTypes.array.isRequired,
	headerImgPath: PropTypes.string,
	headerImgAlt: PropTypes.string,
	transparentBg: PropTypes.bool
};

export default SliderSection;
