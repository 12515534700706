import React from 'react';
import {Link} from 'gatsby';
import classes from './LinkButtonCircular.module.scss';
import PropTypes from 'prop-types';

const LinkButtonCircular = ({to, iconDirection = 'right', icon = true, targetBlank = false, children}) => {
    const classList = [
        classes.buttonCircular,
        iconDirection === 'right' ? classes.iconRight : '',
        iconDirection === 'left' ? classes.iconLeft : '',
        iconDirection === 'down' ? classes.iconDown : ''
    ].join(' ');

    return (
        <>
            {
                to && children
                    ?
                        targetBlank ?
                            <a href={to} target="_blank" className={classList}>
                                {children || 'Read more'}
                                {icon ? <i/> : null}
                            </a>
                            : <Link to={to} className={classList}>
                                {children || 'Read more'}
                                {icon ? <i/> : null}
                            </Link>
                    : null
            }
        </>
    )
};

LinkButtonCircular.propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.string,
    iconDirection: PropTypes.string,
    icon: PropTypes.bool,
    targetBlank: PropTypes.bool
};

export default LinkButtonCircular;
