import React, {useEffect} from 'react';
import {withPrefix} from 'gatsby';
import {motion, useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import classes from './CardSectionSec.module.scss';
import LinkButtonCircular from '../../UI/ButtonCircular/LinkButtonCircular';
import config from '../../../config/config';
import Comment from '../../Comment/Comment';
import {useWindowWidth} from '@react-hook/window-size/throttled';
import PropTypes from 'prop-types';
import {css} from '@emotion/core';

const CardSectionSec = ({
    position = 'left',
    fullScreen = false,
    data: {
        bgImg,
        main,
        additional,
        more
    },
    smallBg = false,
    indentBottom = true,
    authorComponent,
		paddingTop = false,
		background = false,
		offAnimations = false,
		smallTextOnMobile = false,
	link
}) => {
	const sectionAnim = useAnimation();
	const bgImgAnim = useAnimation();
	const bgAnim = useAnimation();
	const additionalImgAnim = useAnimation();
	const mainAnim = useAnimation();

	const sectionClassList = [
		classes.section,
		smallBg ? classes.backgroundSmall : '',
		smallTextOnMobile ? classes.smallTextOnMobile : '',
		!indentBottom ? classes.resetIndentBottom : '',
		position === 'left' ? classes.sectionLeft : classes.sectionRight
	].join(' ');

	const windowWidth = useWindowWidth();

	const [ref, inView] = useInView({
		threshold: .1,
		triggerOnce: true,
		rootMargin: '320px'
	});
	useEffect(() => {
		if (inView) {
			sectionAnim.start('visible');
			bgImgAnim.start('visible');
			bgAnim.start('visible');
			additionalImgAnim.start('visible');
			mainAnim.start('visible');
		}
	}, [windowWidth, inView]);

	return (
		<>
			<div ref={ref}>
				<motion.div
					animate={sectionAnim}
					initial={offAnimations ? "visible" : "hidden"}
					variants={{
						visible: {opacity: 1, y: 0},
						hidden: {opacity: 0, y: '500px'}
					}}
					transition={{type: 'spring', stiffness: 40}}
					style={{
						paddingTop,
						background
					}}
					className={sectionClassList}
				>
					<div className={classes.wrapper}
					     style={{maxWidth: fullScreen ? '100%' : null}}
					>
						<motion.div
							animate={bgImgAnim}
							initial={offAnimations ? "visible" : "hidden"}
							variants={{
								visible: {opacity: 1, y: 0},
								hidden: {opacity: 0, y: '500px'}
							}}
							transition={{type: 'spring', stiffness: 40, delay: .2}}
							className={classes.wrapperBgImg}>
							<img srcSet={withPrefix(bgImg)} alt=""/>
						</motion.div>

						<div className={classes.content}>

							<motion.div
								animate={bgAnim}
								initial={offAnimations ? "visible" : "hidden"}
								variants={{
									visible: {opacity: 1, x: 0},
									hidden: {opacity: 0, x: '500px'}
								}}
								transition={{type: 'spring', stiffness: 90, delay: .1}}
								className={classes.background}
							>

							</motion.div>

							<div className={classes.additionalCol}>

								<motion.div
									animate={additionalImgAnim}
									initial={offAnimations ? "visible" : "hidden"}
									variants={{
										visible: {opacity: 1, y: 0},
										hidden: {opacity: .5, y: '600px'}
									}}
									transition={{type: 'spring', stiffness: 40, delay: .5}}
								>
									<div
										className={classes.additionalColImg}
										style={{ backgroundImage: `url('${withPrefix(additional.img)}')` }}
									/>
								</motion.div>

								{
									windowWidth > config.mobileWidth && !!additional.comment
										? <Comment
											isVisible={offAnimations ? true : inView}
											addClass={classes.comment}
											comment={additional.comment}/>
										: null
								}
							</div>
							<motion.div
								animate={mainAnim}
								initial={offAnimations ? "visible" : "hidden"}
								variants={{
									visible: {opacity: 1, x: 0},
									hidden: {opacity: 0, x: position === 'left' ? '500px' : '-500px'}
								}}
								transition={{type: 'spring', stiffness: 40, delay: .7}}
								className={classes.mainCol}
							>
								{
									authorComponent
										? <div css={{marginBottom: '15px'}}>{authorComponent}</div>
										: null
								}

								<h4 className={classes.cardSectionTitle}>{main.title}</h4>

								<div className={classes.cardSectionContent}
								     dangerouslySetInnerHTML={{__html: main.content}}/>

								{
									windowWidth <= config.mobileWidth && !!additional.comment
										? <Comment
											isVisible={offAnimations ? true : inView}
											addClass={classes.comment}
											comment={additional.comment}/>
										: null
								}

								{
									more
										?
										<LinkButtonCircular to={more.link || link} icon={true}>{more.label}</LinkButtonCircular>
										: null
								}
							</motion.div>
						</div>
					</div>
				</motion.div>
			</div>
		</>
	)
};

CardSectionSec.propTypes = {
	data: PropTypes.shape({
		main: PropTypes.object.isRequired,
		additional: PropTypes.object.isRequired,
		bgImg: PropTypes.string,
		more: PropTypes.object
	}).isRequired,
	paddingTop: PropTypes.string,
	indentBottom: PropTypes.bool,
	position: PropTypes.oneOf(['left', 'right']),
	authorComponent: PropTypes.node,
	link: PropTypes.string
};

export default CardSectionSec;
