import React, {forwardRef} from 'react';
import {ReactSVG} from 'react-svg';
import {withPrefix} from 'gatsby';
import PropTypes from 'prop-types';
import SliderSectionSlide from '../SliderSectionSlide/SliderSectionSlide';

const SliderSectionPrevArrow = forwardRef(({wrapClass, className, onClick}, ref) => {
	return (
		<>
			<button className={className} onClick={onClick} ref={ref}>
				<ReactSVG className={wrapClass} src={withPrefix('images/svg/arrow-prev.svg')}/>
			</button>
		</>
	)
});

SliderSectionPrevArrow.propTypes = {
	onClick: PropTypes.func,
	wrapClass: PropTypes.string,
	className: PropTypes.string
};

export default SliderSectionPrevArrow;
