import React, { useEffect } from 'react';
import { withPrefix } from 'gatsby';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import classes from './HeroSecondSection.module.scss';
import LinkButtonCircular from '../../UI/ButtonCircular/LinkButtonCircular';
import PropTypes from 'prop-types';
import LinkDropdownCircular from '../../UI/LinkDropdownCircular/LinkDropdownCircular';

const HeroSecondSection = ({
  authorComponent,
  fullScreen = false,
  position = 'left',
  ebcContent,
  data: {
    banner: {
      firstImg,
      secondImg
    },
    main: {
      title,
      author,
      content,
      brandPath
    },
    files
  }
}) => {
  const sectionAnim = useAnimation();
  const bgImgAnim = useAnimation();
  const additionalImgAnim = useAnimation();
  const mainAnim = useAnimation();
  const titleAnim = useAnimation();
  const contentAnim = useAnimation();

  const fullScreenProps = {
    paddingLeft: position === 'left' && fullScreen ? '0' : null,
    paddingRight: position === 'right' && fullScreen ? '0' : null
  };

  const sectionClassList = [
    classes.section,
    position === 'left' ? classes.sectionLeft : classes.sectionRight
  ].join(' ');

  const [ref, inView] = useInView({
    threshold: .1,
    triggerOnce: true,
    rootMargin: '320px'
  });

  useEffect(() => {
    if (inView) {
      sectionAnim.start('visible');
      bgImgAnim.start('visible');
      additionalImgAnim.start('visible');
      mainAnim.start('visible');
      titleAnim.start('visible');
      contentAnim.start('visible');
    }
  }, [sectionAnim, inView]);

  return (
    <>
      <div ref={ref}>
        <motion.div
          animate={sectionAnim}
          initial="hidden"
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: '500px' }
          }}
          transition={{ type: 'spring', stiffness: 40 }}
          className={sectionClassList}
        >
          <div
            style={{ ...fullScreenProps }}
            className={classes.content}>
            {
              authorComponent
                ? <div css={{ marginBottom: '15px' }}>{authorComponent}</div>
                : null
            }

            <motion.div
              animate={titleAnim}
              initial="hidden"
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: '-500px' }
              }}
              transition={{ type: 'spring', stiffness: 40, delay: .2 }}
            >
              <h2>{title}</h2>
              {
                ebcContent
                  ? <div css={{ marginBottom: '15px' }}>{ebcContent}</div>
                  : null
              }
            </motion.div>

            <div className={classes.brandPlace}>
              <div>{author}</div>
              {brandPath && (
                <img src={withPrefix(brandPath)} alt='' />
              )}
            </div>

            <motion.div
              animate={contentAnim}
              initial="hidden"
              variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: '-500px' }
              }}
              transition={{ type: 'spring', stiffness: 40, delay: .4 }}
              dangerouslySetInnerHTML={{ __html: content }} />

            {files ? <LinkDropdownCircular
              file={files}
              targetBlank={true}
              iconDirection={'down'}
            >
              {files.label_main}
            </LinkDropdownCircular> : null}
          </div>

          <div className={classes.banner}>
            <motion.div
              animate={additionalImgAnim}
              initial="hidden"
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: '500px' }
              }}
              transition={{ type: 'spring', stiffness: 40 }}
              className={classes.secondImg} css={{ backgroundImage: `url(${withPrefix(secondImg)})` }} />

            <motion.div
              animate={bgImgAnim}
              initial="hidden"
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: '500px' }
              }}
              transition={{ type: 'spring', stiffness: 40, delay: .3 }}
              className={classes.firstImg} css={{ backgroundImage: `url(${withPrefix(firstImg)})` }} />
          </div>
        </motion.div>
      </div>
    </>
  )
};

HeroSecondSection.propTypes = {
  authorComponent: PropTypes.node,
  position: PropTypes.oneOf(['left', 'right']),
  data: PropTypes.shape({
    banner: PropTypes.object.isRequired,
    main: PropTypes.object.isRequired,
    files: PropTypes.object
  }).isRequired,
};

export default HeroSecondSection;
