import {useEffect, useState} from 'react';
import {window as monadsWindow} from 'browser-monads';
import {hasWindow} from '../utils/utils';

export default (ref) => {
	const [scrollToParentState, setScrollToParentState] = useState({
		top: 0
	});

	useEffect(() => {
		const findParentWithHeight = (element, elHeight) => {
			const parentElement = element.parentElement;

			if (parentElement === document.body) return document.body;
			if (!elHeight) elHeight = element.getBoundingClientRect().height;

			const pos = parentElement.getBoundingClientRect();
			return pos.height !== elHeight && pos.height !== 0
				? parentElement
				: findParentWithHeight(parentElement);
		};

		const stickyEl = ref.current;
		const parentEl = findParentWithHeight(stickyEl);

		const scrollHandler = () => {
			if (ref.current) {
				const stickyElPos = stickyEl.getBoundingClientRect();
				const parentElPos = parentEl.getBoundingClientRect();

				if (parentElPos.y < 0 && parentElPos.bottom - stickyElPos.height > 0) {
					setScrollToParentState({
						...scrollToParentState,
						top: Math.abs(parentElPos.top)
					})
				}
			}
		};

		hasWindow() && monadsWindow.addEventListener('scroll', scrollHandler);
		hasWindow() && monadsWindow.addEventListener('resize', scrollHandler);
		return () => {
			hasWindow() && monadsWindow.removeEventListener('scroll', scrollHandler);
			hasWindow() && monadsWindow.removeEventListener('resize', scrollHandler);
		}
	}, []);

	return [scrollToParentState];
}
