import React, {useEffect} from 'react';
import {useAnimation, motion} from 'framer-motion';
import {ReactSVG} from 'react-svg';
import {withPrefix} from 'gatsby';
import classes from './Comment.module.scss';
import PropTypes from 'prop-types';

const Comment = ({comment: {text, subtext}, isVisible, addClass = ''}) => {
	const commentAnim = useAnimation();

	useEffect(() => {
		if (isVisible) {
			commentAnim.start('visible');
		}
	}, [isVisible]);

	const classList = [
		classes.comment,
		addClass
	].join(' ');

	return (
		<>
			<motion.div
				animate={commentAnim}
				initial={isVisible ? 'visible' : 'hidden'}
				variants={{
					visible: {opacity: 1, y: 0},
					hidden: {opacity: 0, y: '500px'}
				}}
				transition={{type: 'spring', stiffness: 40, delay: .6}}
				className={classList}
			>
				<ReactSVG src={withPrefix('images/svg/comas.svg')} />

				<h4>{text}</h4>

				{
					subtext
						? <p>{subtext}</p>
						: null
				}
			</motion.div>
		</>
	)
};

Comment.propTypes = {
	comment: PropTypes.shape({
		text: PropTypes.string.isRequired,
		subtext: PropTypes.string
	}).isRequired,
	isVisible: PropTypes.bool,
	addClass: PropTypes.string
};

export default Comment;
