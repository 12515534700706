import React, {useEffect} from 'react';
import classes from './Content.module.scss';
import {motion, useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import PropTypes from 'prop-types';

const Content = ({children}) => {
	const [ref, inView] = useInView({
		threshold: .1,
		triggerOnce: true,
		rootMargin: '320px'
	});

	useEffect(() => {
		if (inView) {
			animation.start('visible');
		}
	}, [inView]);

	const animation = useAnimation();

	return (
		<>
			{
				children
					?
					<div ref={ref}>

						<motion.div
							animate={animation}
							initial="hidden"
							variants={{
								visible: {opacity: 1, y: 0},
								hidden: {opacity: 0, y: '500px'}
							}}
							className={classes.content}
							transition={{type: 'spring', stiffness: 40}}
							dangerouslySetInnerHTML={{__html: children}} />

					</div>
					: null
			}
		</>
	)
};

Content.propTypes = {
	children: PropTypes.node.isRequired
};

export default Content;
