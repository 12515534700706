import React, {useEffect} from 'react';
import {withPrefix} from 'gatsby';
import {motion, useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import classes from './MessageWithImgSection.module.scss';
import { useWindowWidth } from '@react-hook/window-size/throttled';
import config from '../../../config/config';
import PropTypes from 'prop-types';

const MessageWithImgSection = ({
   data: {
       hero,
       content,
       footer,
       imgPath,
       addBackgroundCard = false
   }
}) => {
	const sectionAnim = useAnimation();
	const imgAnim = useAnimation();
	const dataAnim = useAnimation();
	const heroAnim = useAnimation();
	const textAnim = useAnimation();
	const footerAnim = useAnimation();

	const windowWidth = useWindowWidth();

	const [ref, inView] = useInView({
		threshold: .1,
		triggerOnce: true,
		rootMargin: '320px'
	});

	useEffect(() => {
		if (inView) {
			sectionAnim.start('visible');
			imgAnim.start('visible');
			dataAnim.start('visible');
			heroAnim.start('visible');
			textAnim.start('visible');
			footerAnim.start('visible');
		}
	}, [windowWidth, inView]);

	return (
		<>
			<div ref={ref}>
				<motion.div
					animate={sectionAnim}
					initial="hidden"
					variants={{
						visible: {opacity: 1, y: 0},
						hidden: {opacity: 0, y: '500px'}
					}}
					transition={{type: 'spring', stiffness: 40}}
					className={classes.section}
				>

					{
						addBackgroundCard
							? <div className={classes.bg}/>
							: null
					}

					<div className={classes.contentWrapper}>
						<motion.div
							animate={dataAnim}
							initial="hidden"
							variants={{
								visible: {opacity: 1, x: 0},
								hidden: {opacity: 0, x: '-500px'}
							}}
							transition={{type: 'spring', stiffness: 40, delay: .2}}
							className={classes.content}
						>
							<motion.div
								animate={heroAnim}
								initial="hidden"
								variants={{
									visible: {opacity: 1, x: 0},
									hidden: {opacity: 0, x: '-500px'}
								}}
								transition={{type: 'spring', stiffness: 40, delay: .5}}
								className={classes.hero} dangerouslySetInnerHTML={{__html: hero}}/>

							{
								windowWidth > config.mobileWidth
									? <motion.div
										animate={textAnim}
										initial="hidden"
										variants={{
											visible: {opacity: 1, x: 0},
											hidden: {opacity: 0, x: '-500px'}
										}}
										transition={{type: 'spring', stiffness: 40, delay: .8}}
										className={classes.text} dangerouslySetInnerHTML={{__html: content}}/>
									: null
							}

							{
								windowWidth > config.mobileWidth
									? <motion.div
										animate={footerAnim}
										initial="hidden"
										variants={{
											visible: {opacity: 1, x: 0},
											hidden: {opacity: 0, x: '-500px'}
										}}
										transition={{type: 'spring', stiffness: 40, delay: 1}}
										className={classes.footer} dangerouslySetInnerHTML={{__html: footer}}/>
									: null
							}

						</motion.div>

						<motion.div
							animate={imgAnim}
							initial="hidden"
							variants={{
								visible: {opacity: 1, x: 0},
								hidden: {opacity: 0, x: '500px'}
							}}
							transition={{type: 'spring', stiffness: 40, delay: .2}}
							className={classes.imgWrapper}
						>
							<img srcSet={withPrefix(imgPath)} alt=""/>
						</motion.div>
					</div>

					{
						windowWidth <= config.mobileWidth
							? <motion.div
								animate={textAnim}
								initial="hidden"
								variants={{
									visible: {opacity: 1, x: 0},
									hidden: {opacity: 0, x: '-500px'}
								}}
								transition={{type: 'spring', stiffness: 40, delay: .8}}
								className={classes.text} dangerouslySetInnerHTML={{__html: content}}/>
							: null
					}

					{
						windowWidth <= config.mobileWidth
							? <motion.div
								animate={footerAnim}
								initial="hidden"
								variants={{
									visible: {opacity: 1, x: 0},
									hidden: {opacity: 0, x: '-500px'}
								}}
								transition={{type: 'spring', stiffness: 40, delay: 1}}
								className={classes.footer} dangerouslySetInnerHTML={{__html: footer}}/>
							: null
					}

				</motion.div>
			</div>
		</>
	)
};

MessageWithImgSection.propTypes = {
	data: PropTypes.shape({
		hero: PropTypes.string.isRequired,
		imgPath: PropTypes.string.isRequired,
		content: PropTypes.string,
		footer: PropTypes.string,
		addBackgroundCard: PropTypes.bool
	}).isRequired
};

export default MessageWithImgSection;
